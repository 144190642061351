import React from "react";
import { OurTaskStatus, ParentTask } from "./types";
import { Spinner } from "../../utils/Spinner";

type BGColorMap = Record<OurTaskStatus | "FATAL_ERROR", string>;

const summaryBGColorMap: BGColorMap = {
  FAILED: "!tw-bg-red-500 !tw-text-white",
  SUCCESS: "!tw-bg-green-500",
  PENDING: "!tw-bg-blue-500 !tw-text-white",
  PROGRESS: "!tw-bg-blue-500 !tw-text-white",
  PARTIALLY_FAILED: "!tw-bg-yellow-500",
  FATAL_ERROR: "!tw-bg-gray-500 !tw-text-white",
};

type Props = {
  task: ParentTask;
  isPlaceholder?: boolean;
};

export const TaskProgressbar: React.FC<Props> = ({ task, isPlaceholder }) => {
  const progress = task?.progress;

  const calcBase = Math.round(
    ((progress.success + progress.error) / progress.total) * 100
  );

  const calcProgressForProgressbar = Math.max(calcBase, 3);

  const progressTotalPercentage =
    progress && progress.total > 0
      ? `${Math.min(calcProgressForProgressbar, 100)}%`
      : "0%";

  const displayProgress =
    progress && progress.total > 0 ? `${Math.min(calcBase, 100)}%` : "0%";

  const inProgress = ["PROGRESS", "PENDING"].includes(task.status_from_result);

  const bgColor = summaryBGColorMap[task.status_from_result];

  return (
    <div>
      <div className="tw-flex tw-items-center tw-gap-2">
        <div className="tw-w-full tw-bg-gray-200 tw-rounded-md">
          <div
            className={`tw-font-medium tw-flex tw-justify-end tw-items-center tw-gap-2 tw-p-0.5 tw-px-3 tw-rounded-md ${bgColor}`}
            style={{
              width: progressTotalPercentage,
            }}
          >
            {inProgress && <Spinner size="tiny" />} {displayProgress}
          </div>
        </div>
      </div>
      <small className="tw-text-gray-500 tw-text-xs">
        {isPlaceholder
          ? "Loading..."
          : `${progress.success + progress.error} / ${progress.total} with ${
              progress.error
            } error(s) and ${progress.success} success(es)`}
      </small>
    </div>
  );
};
