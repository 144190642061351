import { LanguageCode } from "../customers/customerlanguages";
import { TranslationInfo } from "../machine-translation/PreviouslyMachineTranslatedTextRailList";
import api from "./api";
import { TaskQueue } from "./taskQueueApi";
import { TaskId } from "./types";

export const BASE_URL = "/api/_internal/mt/";

export type Url = string;

export type DoneTranslateFileResponse = {
  url: Url;
  target_file_path: string;
  original_target_file_name: string;
};

export type DoneTranslateTextResponse = {
  translated_text: string;
  translation_event_id: number;
};

export type TranslateFileResponse = {
  task_ids: { [language: string]: TaskId };
  path_to_source_file: string;
};

export type DownloadFilesResponse = {
  url: Url;
};

export type TranslateTextResponse = {
  source_language: string; // useful when source was auto-detected
  task_ids: { [language: string]: TaskId };
};

export class MachineTranslateTaskQueue<
  T = DoneTranslateFileResponse | DoneTranslateTextResponse
> extends TaskQueue<T> {
  constructor(token: string, pollInterval?: number) {
    super(token, pollInterval, "Translation Cancelled");
  }
  // This enables us to wait for a specific language to be translated
  private requests: {
    [language: string]: Promise<T>;
  } = {};

  async waitUntilTranslateLanguageIsDone(language: string): Promise<T> {
    return this.requests[language].finally(() => {
      delete this.requests[language];
    });
  }

  cancelAll(): void {
    this.getTaskIds().map((taskId) => {
      this.cancelTask(taskId);
    });
  }

  async translateFile(
    sourceFile: File,
    sourceLanguage: string | null,
    targetLanguages: string[],
    sourceContext: string,
    configGroupId?: number
  ): Promise<string> {
    const formData = new FormData();
    formData.append("source_file", sourceFile, sourceFile.name);
    formData.append("source_language", sourceLanguage || "");
    for (const targetLanguage of targetLanguages) {
      formData.append("target_languages", targetLanguage);
    }
    if (configGroupId) {
      formData.append("config_group_id", configGroupId.toString());
    }
    if (sourceContext) {
      formData.append("source_context", sourceContext);
    }
    const response = await api.post<TranslateFileResponse>(
      `${BASE_URL}translate/file`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          token: this.token,
        },
      }
    );
    Object.entries(response.data.task_ids).forEach(([language, taskId]) => {
      this.addTaskId(taskId, "Machine Translate File");
      this.requests[language] = this.waitUntilTaskIsDone(taskId);
    });
    return response.data.path_to_source_file;
  }

  async downloadFiles(
    sourceFilePath: string,
    targetFilePaths: string[]
  ): Promise<string> {
    const data = {
      source_file_path: sourceFilePath,
      target_file_paths: targetFilePaths,
    };
    const response = await api.post<DownloadFilesResponse>(
      `${BASE_URL}translate/download-files`,
      data,
      {
        headers: {
          token: this.token,
        },
      }
    );
    return response.data.url;
  }

  async translateText(
    sourceText: string,
    sourceContext: string,
    sourceLanguage: string | null,
    targetLanguages: string[],
    configGroupId?: number
  ): Promise<string> {
    const data = {
      source_text: sourceText,
      source_context: sourceContext,
      source_language: sourceLanguage || "",
      target_languages: targetLanguages,
      config_group_id: configGroupId,
    };
    const response = await api.post<TranslateTextResponse>(
      `${BASE_URL}translate/text`,
      data,
      {
        headers: {
          token: this.token,
        },
      }
    );
    Object.entries(response.data.task_ids).forEach(([language, taskId]) => {
      this.addTaskId(taskId, "Machine Translate Text");
      this.requests[language] = this.waitUntilTaskIsDone(taskId);
    });

    return response.data.source_language;
  }
}

export async function getPreviousMachineTranslations(
  token: string,
  {
    start,
    end,
    query_string,
    language_code,
  }: {
    start: string;
    end: string;
    query_string?: string;
    language_code?: LanguageCode;
  }
): Promise<TranslationInfo[]> {
  const response = await api.get<TranslationInfo[]>(
    BASE_URL + "previous-translations",
    {
      headers: { token },
      params: { start, end, query_string, language_code },
    }
  );

  return response.data;
}

export async function modifyTranslatedText(
  token: string,
  translationEventId: number,
  modifiedText: string
): Promise<unknown> {
  const response = await api.put(
    BASE_URL + "modify-translated-text",
    {
      translation_event_id: translationEventId,
      modified_text: modifiedText,
    },
    {
      headers: { token },
    }
  );

  return response.data;
}
