import React from "react";
import { Job } from "./types";
import { JobsTableRows } from "./JobsTableRow";

type Props = {
  fetchingJobs: boolean;
  jobs: Job[];
};

export const JobsTable: React.FC<Props> = ({ fetchingJobs, jobs }) => {
  return (
    <table className="txu-table txu-compact txu-center-all">
      <thead>
        <tr>
          <th>ID</th>
          <th>Action</th>
          <th>Status</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        <JobsTableRows
          jobs={jobs}
          isLoading={fetchingJobs}
          notFound={!jobs.length}
        />
      </tbody>
    </table>
  );
};
