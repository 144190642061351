import React from "react";
import { JobSummary } from "./types";
import { JobSummaryTableRow } from "./JobSummaryTableRow";

type Props = {
  summary: JobSummary[];
  loadingRetry: boolean;
  OnRetryFailedDocuments: (ids: string[]) => void;
  observeLastElementInList: (element: HTMLDivElement) => void;
  scrollElement: React.RefObject<HTMLDivElement>;
};

export const JobSummaryTable: React.FC<Props> = ({
  summary,
  loadingRetry,
  OnRetryFailedDocuments,
  observeLastElementInList,
  scrollElement,
}) => {
  return (
    <div className="tw-max-h-[900px] tw-overflow-y-auto" ref={scrollElement}>
      <table className="txu-table txu-compact txu-center-all">
        <thead className="tw-sticky tw-top-0 tw-z-20 tw-bg-white">
          <tr>
            <th>Resource</th>
            <th>Name / ID</th>
            <th>Reason</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {summary.map((s, index) => {
            const isLastElement = index === summary.length - 1;
            return (
              <JobSummaryTableRow
                key={s.last_report.resource_id}
                summary={s}
                isLastElement={isLastElement}
                observeLastElementInList={observeLastElementInList}
                loadingRetry={loadingRetry}
                OnRetryFailedDocuments={OnRetryFailedDocuments}
              />
            );
          })}
          {summary.length < 1 && (
            <tr>
              <td colSpan={6}>No results found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
