import React, { useMemo, useState } from "react";
import { Dropdown } from "./tailwind";
import { Props as ButtonProps } from "./tailwind/button/Button";
import { useGetRewritePromptsQuery } from "../api/rewritePromptSlice";
import { Option } from "./tailwind/dropdown/Dropdown";
import { useSelector } from "react-redux";
import { GptGenerationTaskQueue } from "../api/gptApi";
import { RootState } from "../utils/store";
import { LanguageCode } from "../customers/customerlanguages";

type Props = {
  input: string;
  languageCode: LanguageCode;
  buttonProps?: Omit<ButtonProps, "onClick" | "onChange">;
  tooltipPosition?: "right" | "left" | "down" | "up";
  noTooltip?: boolean;
  onGenerationCompleteCallback?: (newText: string) => void;
  getIsGenerating?: (generating: boolean) => void;
};

export const AIRewriteTextButton: React.FC<Props> = ({
  input,
  languageCode,
  tooltipPosition = "right",
  noTooltip,
  buttonProps,
  onGenerationCompleteCallback,
  getIsGenerating,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [isGenerating, setIsGenerating] = useState(false);
  const {
    data: rewritePrompts = [],
    isLoading: isFetchingRewritePrompts,
  } = useGetRewritePromptsQuery(undefined);

  const isDisabled = buttonProps?.disabled || !input;

  const isLoading =
    buttonProps?.loading || isFetchingRewritePrompts || isGenerating;
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const dropdownOptions = useMemo<Option<number>[]>(() => {
    if (!rewritePrompts.length) return [];

    return [
      {
        key: "heading",
        value: 0,
        text: "Rewrite with AI",
        type: "header",
      },
      ...rewritePrompts.map(({ id, name }) => ({
        key: id.toString(),
        value: id,
        text: name,
      })),
    ];
  }, [rewritePrompts]);

  const onGenerate = async (rewritePromptId: number): Promise<void> => {
    setIsGenerating(true);
    getIsGenerating?.(true);
    const taskQueue = new GptGenerationTaskQueue(token);
    const { generated_texts } = await taskQueue
      .generateRewrite(rewritePromptId, input, languageCode, true)
      .finally(() => {
        setIsGenerating(false);
        getIsGenerating?.(false);
      });
    onGenerationCompleteCallback?.(generated_texts[0][languageCode]);
  };
  return (
    <div
      {...(!dropdownIsOpen &&
        !isDisabled &&
        !noTooltip && {
          "data-balloon": "Rewrite with AI",
          "data-balloon-pos": tooltipPosition,
        })}
    >
      <Dropdown<number>
        control="button"
        options={dropdownOptions}
        onChange={onGenerate}
        value={undefined}
        size="sm"
        buttonProps={{
          variant: "primary",
          ...buttonProps,
          icon: true,
          disabled: isDisabled,
          loading: isLoading,
        }}
        content={<i className="material-icons">auto_fix_high</i>}
        isOpenCallback={setDropdownIsOpen}
      />
    </div>
  );
};
