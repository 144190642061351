import React from "react";

import { Tab } from "../components/Tab";
import { MachineTranslateFile } from "./MachineTranslateFile";
import { MachineTranslateText } from "./MachineTranslateText";

export const MachineTranslate: React.FC = () => {
  const panes = [
    {
      menuItem: {
        key: "text",
        icon: <i className="material-icons">language</i>,
        heading: "Translate text",
        subHeading: "Directly in your browser",
      },
      content: <MachineTranslateText />,
    },
    {
      menuItem: {
        key: "file",
        icon: <i className="material-icons">upload_file</i>,
        heading: "Translate files",
        subHeading: "CSV, Excel & more...",
      },
      content: <MachineTranslateFile />,
    },
  ];

  return <Tab panes={panes} />;
};
