import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { Job } from "./types";
import {
  getCustomerJobDetail,
  refreshTasksStatus,
  retryJob,
} from "../../api/jobsApi";
import { findRetryableDocumentIds, friendlyStatus } from "./utils";
import { Button, Icon } from "../../components/tailwind";
import { bulkActionLabels } from "../../ui/BulkActionsDropdown";
import { useGetAuthQuery } from "../../api/authApi";
import { Spinner } from "../../utils/Spinner";
import { JobProgressbarHandler } from "./JobProgressbarHandler";
import { JobsSummaryTableTabs } from "./JobsTableTabs";

type Props = {
  jobId: string;
};

export const JobDetail: React.FC<Props> = ({ jobId }) => {
  const { data: auth } = useGetAuthQuery();

  const token = useSelector((state: RootState) => state.auth.token);

  const [retryClicked, setRetryClicked] = useState(false);
  const [job, setJob] = useState<Job | null>(null);
  const [showFullArguments, setShowFullArguments] = useState(false);

  useEffect(() => {
    getCustomerJobDetail(token, jobId).then((job) => {
      setJob(job);
    });
  }, [token, jobId]);

  const userIsStaff = useMemo(() => {
    if (!auth) return false;
    return auth.user.is_staff;
  }, [auth]);

  const fullArguments = JSON.stringify(job?.arguments, null, 2);
  const shortArguments =
    fullArguments?.length > 300 ? fullArguments.slice(0, 300) + "..." : "";

  const waitedUntilRunningTasksExists = useRef(false);

  function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const cleanUpRunningTasks = (): void => {
    setJob((prevJob) => ({
      ...prevJob,
      running_tasks: [],
    }));
  };

  const updateRunningTasks = async (
    tryAgain = false,
    tries = 0
  ): Promise<void> => {
    await sleep(300);
    if (!job?.id) return Promise.resolve();
    const runningTasks = await refreshTasksStatus(token, job?.id);
    if (runningTasks.length > 0) {
      setJob((prevJob) => ({
        ...prevJob,
        running_tasks: runningTasks,
      }));
    }
    if (tryAgain && tries < 4) {
      return updateRunningTasks(true, tries + 1);
    }

    if (!waitedUntilRunningTasksExists.current) {
      waitedUntilRunningTasksExists.current = runningTasks.length > 0;
      if (tries >= 10) {
        return Promise.resolve();
      }

      return updateRunningTasks(true, tries + 1);
    }
    if (!runningTasks.length) {
      if (tries < 7) {
        return updateRunningTasks(true, tries + 1);
      }
      return Promise.resolve();
    }
    return updateRunningTasks();
  };

  const retryableDocumentIds = useMemo(() => {
    return findRetryableDocumentIds(job);
  }, [job]);

  const OnRetryFailedDocuments = (ids: string[]): void => {
    setRetryClicked(true);
    retryJob(token, job?.id, ids).then(() => {
      updateRunningTasks()
        .then(() => {
          waitedUntilRunningTasksExists.current = false;
          getCustomerJobDetail(token, job?.id.toString())
            .then((job) => {
              setJob(job);
            })
            .finally(() => {
              cleanUpRunningTasks();
              setRetryClicked(false);
            });
        })
        .catch(() => {
          cleanUpRunningTasks();
          setRetryClicked(false);
        });
    });
  };

  const fetchingJob = useMemo(() => {
    return job ? false : true;
  }, [job]);

  return (
    <>
      <h1>Job {jobId}</h1>
      <div className="txu-log-item">
        <h5 className="txu-log-item-label">Action</h5>
        <div className="txu-log-item-value">
          {fetchingJob ? (
            <Spinner align="left" />
          ) : (
            bulkActionLabels?.[job?.action_name] ?? job?.action_name
          )}
        </div>
      </div>

      <div className="txu-log-item">
        <h5 className="txu-log-item-label">Status</h5>
        <div className="txu-log-item-value">
          {fetchingJob ? (
            <Spinner align="left" />
          ) : (
            friendlyStatus(job?.status_from_tasks_result)
          )}
        </div>
      </div>
      <div className="txu-log-item">
        <h5 className="txu-log-item-label">Created</h5>
        <div className="txu-log-item-value">
          {fetchingJob ? <Spinner align="left" /> : job?.created}
        </div>
      </div>
      <div className="txu-log-item">
        <h5
          className={`txu-log-item-label ${
            retryClicked ? "tw-animate-pulse" : ""
          }`}
        >
          Progress
          <br />
          <span className="tw-text-xs tw-text-gray-500">(Last run)</span>
        </h5>
        <JobProgressbarHandler
          job={job}
          fetchingJob={fetchingJob}
          retryClicked={retryClicked}
        />
      </div>
      <div className="tw-py-6">
        <div className="tw-flex tw-flex-col tw-gap-2">
          <h5 className="tw-text-gray-600">Results</h5>

          <div className="tw-flex tw-gap-4 tw-ml-2">
            {retryableDocumentIds.length > 0 && (
              <Button
                compact
                icon
                variant="primary-alt"
                size="sm"
                onClick={() => OnRetryFailedDocuments(retryableDocumentIds)}
                disabled={retryClicked}
                loading={retryClicked}
              >
                <Icon name="refresh" />
                Retry Failed Documents
              </Button>
            )}
            {job?.failed_product_list_url && (
              <>
                <a
                  href={job.failed_product_list_url}
                  target="_blank"
                  rel="noreferrer"
                  className="txu-btn txu-sm txu-secondary-alt txu-compact"
                >
                  View Failed Documents...
                </a>
              </>
            )}
          </div>
        </div>
        {fetchingJob ? (
          <Spinner align="left" />
        ) : (
          <JobsSummaryTableTabs
            job={job}
            retryClicked={retryClicked}
            OnRetryFailedDocuments={OnRetryFailedDocuments}
          />
        )}
      </div>
      {userIsStaff && (
        <div className="txu-log-item">
          <h5 className="txu-log-item-label tw-flex tw-flex-col tw-items-start tw-gap-2">
            Arguments{" "}
            {!!shortArguments && (
              <Button
                onClick={() => setShowFullArguments(!showFullArguments)}
                size="sm"
                compact
              >
                {showFullArguments ? "Show less" : "Show more"}
              </Button>
            )}
          </h5>
          <pre className="txu-log-item-value tw-break-all tw-whitespace-pre-wrap">
            {!shortArguments
              ? fullArguments
              : showFullArguments
              ? fullArguments
              : shortArguments}
          </pre>
        </div>
      )}
    </>
  );
};
