/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { Text } from "../components/Text";
import { useGetAuthQuery } from "../api/authApi";
import { requestProofreading } from "../api/requestTranslationApi";
import { useSelector } from "react-redux";
import { RootState } from "../utils/store";
import { useGetCustomerQuery } from "../api/customerApi";
import {
  LanguageCode,
  renderLanguageFromCode,
} from "../customers/customerlanguages";
import { Button } from "../components/tailwind";
import { Props as ButtonProps } from "../components/tailwind/button/Button";

export type ProofreadingText = {
  text: string;
  language: LanguageCode;
};

export type ProofreadingFile = {
  pathToFile: string;
  fileName: string;
  language: LanguageCode;
};

type ProofreadingRequest<T extends ProofreadingText | ProofreadingFile> = {
  original: T;
  requests: T[];
};

export type Props<
  T extends ProofreadingText | ProofreadingFile = ProofreadingText
> = {
  proofreadingRequest: ProofreadingRequest<T>;
  buttonProps?: Partial<ButtonProps & { "data-testid": string }>;
  onSendToProofreadingCallback?: () => void;
};

export function ProofreadRequestModal<
  T extends ProofreadingText | ProofreadingFile = ProofreadingText
>({
  proofreadingRequest,
  buttonProps,
  onSendToProofreadingCallback,
}: Props<T>): JSX.Element {
  const { data: customer } = useGetCustomerQuery();

  const itemToProofread = proofreadingRequest.original;
  const canProofread = !!itemToProofread;

  const builtButtonProps: ButtonProps & { "data-testid": string } = {
    content: "Send for proofreading...",
    variant: "primary",
    size: "sm",
    "data-testid": "proofread-request-button",
    compact: true,
    ...buttonProps,
  };

  const token = useSelector((state: RootState) => state.auth.token);
  const { data: auth, isLoading: isAuthLoading } = useGetAuthQuery();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isUrgent, setIsUrgent] = useState(false);
  const [additionalInstructions, setAdditionalInstructions] = useState("");

  const [jobIds, setJobIds] = useState<Record<string, string>>({});
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    if (isAuthLoading || !auth) return;
    setEmail(auth.user.email);
  }, [auth, isAuthLoading, open]);

  const handleRequestProofreading = async (): Promise<void> => {
    setIsRequesting(true);
    const { original, requests } = proofreadingRequest;
    const promises = requests.map((request) => {
      let reqText: string | undefined;
      let reqFile: string | undefined;
      let oriFile: string | undefined;
      let oriText: string | undefined;
      if ("text" in request && "text" in original) {
        reqText = request.text;
        oriText = original.text;
      } else if ("pathToFile" in request && "pathToFile" in original) {
        reqFile = request.pathToFile;
        oriFile = original.pathToFile;
      } else {
        throw new Error("Invalid proofreading request");
      }
      const requestProofreadingParams: Parameters<typeof requestProofreading> = [
        token,
        email,
        oriText,
        oriFile,
        original.language,
        reqText,
        reqFile,
        [request.language],
        isUrgent,
        additionalInstructions,
      ];
      return requestProofreading(...requestProofreadingParams).then((jobId) => {
        return { [request.language]: jobId };
      });
    });
    const response = await Promise.all(promises).finally(() => {
      setIsRequesting(false);
      setIsRequesting(false);
      onSendToProofreadingCallback?.();
    });
    setJobIds(response.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
  };

  function renderLanguage(languageCode: string): string {
    return (
      renderLanguageFromCode(languageCode, customer?.languages ?? []) ?? ""
    );
  }

  if (!canProofread) {
    return <Button {...builtButtonProps} disabled />;
  }

  return (
    <Modal
      data-testid={`proofread-request-modal-${open ? "open" : "closed"}`}
      size="small"
      open={open}
      onClose={(): void => setOpen(false)}
      trigger={
        <Button
          {...builtButtonProps}
          disabled={builtButtonProps?.disabled || isAuthLoading}
          loading={isAuthLoading}
          onClick={(): void => setOpen(true)}
        />
      }
    >
      <Modal.Header>Send to proofreading</Modal.Header>
      <Modal.Content>
        <Dimmer.Dimmable dimmed={isRequesting}>
          <Segment>
            <Grid columns={2} stackable>
              <Divider vertical>
                <Icon name="arrow right" />
              </Divider>
              <Grid.Row verticalAlign="middle">
                <Grid.Column textAlign="center">
                  <Text compact testId="proofread-request-original-language">
                    {renderLanguage(itemToProofread.language) ??
                      "Unknown source language"}
                  </Text>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <div className="tw-flex tw-flex-row tw-gap-4 tw-flex-wrap">
                    {proofreadingRequest.requests.map((request) => (
                      <Text
                        compact
                        testId="proofread-request-request-language"
                        key={request.language}
                      >
                        {renderLanguage(request.language)}
                      </Text>
                    ))}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Divider hidden />
          <Form size="small">
            <Form.Field>
              <label>Email address(es) for returning the delivery *</label>
              <small>
                Separate multiple email addresses with commas <code>(,)</code>
              </small>
              <Input
                data-testid="proofread-request-email"
                type="email"
                placeholder="Email..."
                value={email}
                onChange={(e, { value }): void => setEmail(value)}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                data-testid="proofread-request-urgent"
                checked={isUrgent}
                label={customer?.config.translation_request_urgent_message}
                onChange={(e, { checked }): void => setIsUrgent(checked)}
              />
            </Form.Field>
            <Form.Field>
              <label>Additional instructions</label>
              <TextArea
                data-testid="proofread-request-additional-instructions"
                value={additionalInstructions}
                onChange={(e, { value }): void =>
                  setAdditionalInstructions(value as string)
                }
              />
            </Form.Field>
          </Form>
          <Dimmer active={isRequesting || !!Object.keys(jobIds).length}>
            {Object.keys(jobIds).length > 0 ? (
              <>
                <Icon name="check" />
                <Text size="large">Sent for proofreading!</Text>
                <Text size="small">
                  You will receive an email at <b>{email}</b> when the
                  proofreading is done.
                </Text>
                {Object.entries(jobIds).map(([language, jobId]) => (
                  <Text testId="proofread-request-job-id" key={language}>
                    <span className="tw-text-gray-300">Job ID</span>{" "}
                    {renderLanguage(language)}: <b>{jobId}</b>
                  </Text>
                ))}
                <Text size="small">
                  Save the job ID(s) if you want to ask for status/cancel the
                  proofreading job.
                  <br />
                  All proofreading related questions can be sent to{" "}
                  <a
                    href="mailto:translations@textual.se"
                    style={{ color: "white" }}
                  >
                    translations@textual.se
                  </a>
                </Text>
                <Divider />
                <Button
                  data-testid="proofread-request-done-button"
                  content="Done"
                  color="red"
                  onClick={(): void => setOpen(false)}
                />
              </>
            ) : (
              <>
                <Icon
                  name="spinner"
                  loading
                  data-testid="proofread-request-loading"
                />
                <Text size="large" color="white">
                  Sending for proofreading...
                </Text>
              </>
            )}
          </Dimmer>
        </Dimmer.Dimmable>
      </Modal.Content>
      <Modal.Actions>
        <Button
          data-testid="proofread-request-cancel-button"
          size="sm"
          className="tw-float-left"
          content={"Close"}
          onClick={(): void => setOpen(false)}
        />
        <Button
          data-testid="proofread-request-send-button"
          content="Send for proofreading"
          disabled={!email || isRequesting || Object.keys(jobIds).length > 0}
          variant="primary"
          onClick={handleRequestProofreading}
        />
      </Modal.Actions>
    </Modal>
  );
}
