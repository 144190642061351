import React, { Component, PropsWithChildren } from "react";
import styled from "styled-components";
import {
  newRequestWord,
  searchAsyncVocabulary,
  SearchVocabularyParams,
} from "../api/vocabularyApi";
import { ProductId } from "../products/product";
import { formatTag, VocabularyTreeData } from "../utils/tagutils";
import { LanguageCode } from "../customers/customerlanguages";
import { DirectionProperty } from "csstype";
import { debounce } from "../utils/debounce";
import { connect, ConnectedProps } from "react-redux";
import { RootState, store } from "../utils/store";
import {
  RequestWordModal,
  RequestWordTranslateType,
  RequestWordTranslateTypeToastMessage,
  WordType,
} from "./RequestWordModal/RequestWordModal";
import { Label, Icon } from "semantic-ui-react";
import {
  NotificationAppearance,
  setDjangoToastOpen,
} from "../api/djangoToastSlice";

export function isDocumentElement(el: HTMLElement | Window): boolean {
  if (document?.documentElement === el) {
    return true;
  }
  if (document?.body === el) {
    return true;
  }
  return window === el;
}

type Suggestion = {
  label: string;
  value: string;
  type: string;
  category?: string;
  vocabularyId?: number;
  swedish?: string;
  vocabularyRequestStatus?: string;
};

type Props = {
  alwaysTranslate: boolean;
  componentId: string;
  disableAutoAdd: boolean;
  detailedVocabSuggestion?: boolean;
  excludeFixedPhrases?: boolean;
  focusInput?: boolean;
  headline?: string;
  showVocabType?: boolean;
  includeSeo?: boolean;
  inlineAutogrow?: boolean;
  isMultiple: boolean;
  language?: LanguageCode;
  lexiconEditorDirection?: DirectionProperty;
  limit?: number;
  maxWidth: number;
  minWidth: number;
  onDataChanged: (component: VocabularyLookupNew, data: unknown) => void;
  onLoaded?: (component: VocabularyLookupNew) => void;
  onVocabularyAdded: (tag: VocabularyTreeData | VocabularyTreeData[]) => void;
  onVocabularyCleared: (tag: VocabularyTreeData | VocabularyTreeData[]) => void;
  onVocabularyRemoved: (tag: VocabularyTreeData | VocabularyTreeData[]) => void;
  placeholder?: string;
  selectedTags: VocabularyTreeData[];
  selectedValue: string;
  showTranslate?: boolean;
  showMachineTranslate?: boolean;
  showHypernym: boolean;
  skipCustomerFilter?: boolean;
  showTagTypeHints: boolean;
  tagCategory: string;
  tagType: string;
  textualAppName: string;
  useLexiconEditor: boolean;
  vocabularyRequestAllowed?: boolean;
  vocabularyRequestProductDescription: string;
  vocabularyRequestProductId: ProductId;
};

type State = {
  inputWidth: number;
  inputValue: string;
  vocabularies: VocabularyTreeData[];
  suggestions: Suggestion[];
  activeSuggestion: number;
  isLoadingVocabulary: boolean;
  isInputFocused: boolean;
  initialWordType?: string;
  selectedTags?: VocabularyTreeData[];

  // RequestModal
  newWord: string;
  modalDescriptionInputValue: string;
  modalEnglishInputValue: string;
  wordType: string;
  shouldPopulate: boolean;
  isOpenRequestModal: boolean;
  lexiconData: any;
  hypernymData: VocabularyTreeData;
};

const mapStateToProps = (state: RootState): { token: string | null } => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
});

type ProductListProps = ConnectedProps<typeof connector> & Props;

const connector = connect(mapStateToProps, null);

export default class VocabularyLookupNew extends Component<
  ProductListProps,
  State
> {
  static defaultProps: Partial<ProductListProps> = {
    focusInput: false,
    selectedTags: [],
    selectedValue: "",
  };

  private focusedOptionRef: HTMLDivElement;
  private inputRef: HTMLInputElement;
  private loadVocabulariesDebounce: (data: string) => void;
  private menuRef: HTMLDivElement;
  private mounted: boolean;
  private multiInputRef: HTMLDivElement;
  private scrollToFocusedOptionOnUpdate: boolean;
  private selectedValueRef: HTMLDivElement;
  private sizer: HTMLDivElement;
  private vocabularyLookupRef: HTMLDivElement;

  constructor(props: ProductListProps) {
    super(props);
    this.state = {
      // ShareStates
      inputWidth: 100,
      inputValue: "",
      vocabularies: [],
      suggestions: [],
      activeSuggestion: -1,
      isLoadingVocabulary: false,
      isInputFocused: false,
      // RequestModal
      newWord: "",
      modalDescriptionInputValue: "",
      modalEnglishInputValue: "",
      wordType: "",
      shouldPopulate: false,
      isOpenRequestModal: false,
      lexiconData: null,
      hypernymData: null,
    };
  }

  componentDidMount(): void {
    const { onLoaded, tagCategory, tagType } = this.props;
    this.mounted = true;
    onLoaded?.(this);

    document.addEventListener("mousedown", this._handleClickOutside);

    this.loadVocabulariesDebounce = debounce((value: string) => {
      if (!this.mounted) {
        return;
      }
      this._loadVocabularies(value);
    }, 300);

    // default wordType
    let { wordType } = this.state;
    const defaultWordType =
      tagCategory && tagType ? `${tagCategory}/${tagType}` : "";
    wordType = wordType || defaultWordType;
    this.setState({
      wordType: wordType,
      initialWordType: wordType,
    });
  }

  componentDidUpdate(): void {
    if (
      this.menuRef &&
      this.focusedOptionRef &&
      this.scrollToFocusedOptionOnUpdate
    ) {
      this.scrollIntoView(this.menuRef, this.focusedOptionRef);
    }
    this.scrollToFocusedOptionOnUpdate = false;

    const { focusInput, inlineAutogrow } = this.props;
    if (inlineAutogrow) {
      this.updateInputWidth();
    }
    if (focusInput) {
      this.inputRef.focus();
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener("mousedown", this._handleClickOutside);
    this.mounted = false;
  }

  scrollTo = (el: HTMLDivElement, top: number): void => {
    // with a scroll distance, we perform scroll on the element
    if (isDocumentElement(el)) {
      window.scrollTo(0, top);
      return;
    }

    el.scrollTop = top;
  };

  scrollIntoView = (
    menuEl: HTMLDivElement,
    focusedEl: HTMLDivElement
  ): void => {
    const menuRect = menuEl.getBoundingClientRect();
    const focusedRect = focusedEl.getBoundingClientRect();
    const overScroll = focusedEl.offsetHeight / 3;

    if (focusedRect.bottom + overScroll > menuRect.bottom) {
      this.scrollTo(
        menuEl,
        Math.min(
          focusedEl.offsetTop +
            focusedEl.clientHeight -
            menuEl.offsetHeight +
            overScroll,
          menuEl.scrollHeight
        )
      );
    } else if (focusedRect.top - overScroll < menuRect.top) {
      this.scrollTo(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0));
    }
  };

  updateInputWidth(): void {
    const { isMultiple, minWidth, maxWidth } = this.props;
    const { inputWidth, selectedTags } = this.state;

    if (
      !this.mounted ||
      !this.sizer ||
      (this.sizer && typeof this.sizer.scrollWidth === "undefined")
    ) {
      return;
    }
    const inputMinWidth = minWidth ? minWidth : 100;
    let calculatedInputWidth = this.sizer.scrollWidth + 50;
    if (isMultiple) {
      calculatedInputWidth += selectedTags.length * 35 + 15;
    }
    let newInputWidth = Math.max(calculatedInputWidth, inputMinWidth);

    const inputMaxWidth = maxWidth ? maxWidth : window.innerWidth;
    if (newInputWidth > inputMaxWidth) {
      newInputWidth = inputMaxWidth;
    }

    if (newInputWidth !== inputWidth) {
      this.setState({
        inputWidth: newInputWidth,
      });
    }
  }

  // --  LOGIC METHODS  --

  getValueWithTagId = (data: VocabularyTreeData): string => {
    return data.tag_id ? `${data.value}-${data.tag_id}` : data.value;
  };

  _loadVocabularies = (value: string): void => {
    const {
      detailedVocabSuggestion,
      excludeFixedPhrases,
      headline,
      showVocabType,
      includeSeo,
      language,
      limit,
      showTagTypeHints,
      skipCustomerFilter,
      tagCategory,
      tagType,
      textualAppName,
      token,
      vocabularyRequestAllowed,
    } = this.props;
    if (value.length >= 1) {
      const queryParam: SearchVocabularyParams = {
        q: value,
        limit,
        language,
      };
      if (tagType && tagCategory) {
        // FIXME: Remove as we should always specify the exact type
        if (tagType !== "any") {
          queryParam.type = tagType;
        }
        queryParam.category = tagCategory;
      } else if (headline) {
        queryParam.headline = headline;
      }
      if (includeSeo) {
        queryParam.include_seo = includeSeo;
      }
      if (skipCustomerFilter) {
        queryParam.skip_customer_filter = skipCustomerFilter;
      }
      if (detailedVocabSuggestion) {
        queryParam.detailed_vocab_suggestion = detailedVocabSuggestion;
      }
      if (excludeFixedPhrases) {
        queryParam.exclude_fixed_phrases = excludeFixedPhrases;
      }

      searchAsyncVocabulary(queryParam, token, textualAppName).then(
        (response) => {
          if (!this.mounted) {
            return;
          }

          const data =
            response.data && response.data.length > 0 ? response.data : [];

          const suggestions = data.map((vocabulary) => ({
            label: formatTag(vocabulary, {
              includeSubTagNames: true,
              showTagTypeHints: showTagTypeHints,
              showVocabType: showVocabType,
            }),
            value: this.getValueWithTagId(vocabulary),
            type: vocabulary.type,
            category: vocabulary.category,
            vocabularyId: vocabulary.vocabulary_id,
            swedish: vocabulary.swedish ? vocabulary.swedish : "None",
            vocabularyRequestStatus: vocabulary.vocabulary_request_status,
          }));

          let activeSuggestion = 0;
          if (vocabularyRequestAllowed) {
            suggestions.unshift({
              label:
                "Use this word without translations, and request translation.",
              value: "Not-found-value",
              type: "requestWord",
              category: null,
              vocabularyId: null,
              swedish: null,
              vocabularyRequestStatus: null,
            });
            if (suggestions.length > 1) {
              activeSuggestion = 1;
            }
          }
          this.setState({
            activeSuggestion,
            isLoadingVocabulary: false,
            suggestions,
            vocabularies: data,
          });
        }
      );
    } else {
      this.setState({ suggestions: [], isLoadingVocabulary: false });
    }
  };

  _removeSelections = (): void => {
    if (!this.mounted) {
      return;
    }
    const {
      isMultiple,
      onDataChanged,
      onVocabularyCleared,
      selectedTags,
    } = this.props;
    if (isMultiple) {
      this.setState({ suggestions: [] });
      if (onDataChanged) {
        onDataChanged(this, []);
      }
    } else {
      this.setState({ suggestions: [] });
      if (onDataChanged) {
        onDataChanged(this, {});
      }
    }
    if (onVocabularyCleared) {
      onVocabularyCleared(selectedTags);
    }
  };

  _handleClickOutside = (e: MouseEvent): void => {
    if (
      this.vocabularyLookupRef &&
      !this.vocabularyLookupRef.contains((e.target as unknown) as HTMLElement)
    ) {
      this.setState({
        inputValue: "",
        isInputFocused: false,
        isLoadingVocabulary: false,
        suggestions: [],
      });
    }
  };

  // --  RequestModal methods  --

  handleRequestWord = (value: string): void => {
    this.setState({ newWord: value, isOpenRequestModal: true });
  };

  closeRequestModal = (): void => {
    const { initialWordType } = this.state;
    this.setState({
      newWord: "",
      modalDescriptionInputValue: "",
      modalEnglishInputValue: "",
      wordType: initialWordType,
      shouldPopulate: false,
      isOpenRequestModal: false,
      lexiconData: null,
      hypernymData: null,
    });
  };

  onWordTypeChange = (data: WordType | null): void => {
    this.setState({ wordType: data?.value ?? "" });
  };

  onShouldPopulateChange = (value: boolean): void => {
    this.setState({ shouldPopulate: value });
  };

  onLexiconDataChange = (lexiconData: any): void => {
    this.setState({ lexiconData: lexiconData });
  };

  onHypernymChange = (hypernymData: VocabularyTreeData): void => {
    this.setState({ hypernymData: hypernymData });
  };

  onAddCopiedVocabulary = (data: VocabularyTreeData): void => {
    const { vocabularies } = this.state;
    const { disableAutoAdd, showTagTypeHints } = this.props;
    const newVocabularies = [...vocabularies];
    newVocabularies.push(data);
    this.setState(
      {
        vocabularies: newVocabularies,
      },
      () => {
        if (!disableAutoAdd) {
          this._onAddVocabulary({
            label: formatTag(data, {
              includeSubTagNames: true,
              showTagTypeHints: showTagTypeHints,
            }),
            value: this.getValueWithTagId(data),
            type: data.type,
          });
        }
      }
    );
  };

  requestNewWord = (translateType: RequestWordTranslateType): void => {
    if (!this.mounted) {
      return;
    }
    const {
      token,
      textualAppName,
      language,
      vocabularyRequestProductId,
      vocabularyRequestProductDescription,
      disableAutoAdd,
      alwaysTranslate,
      showTagTypeHints,
    } = this.props;
    const {
      newWord,
      wordType,
      shouldPopulate,
      modalDescriptionInputValue,
      modalEnglishInputValue,
      vocabularies,
      lexiconData,
      hypernymData,
    } = this.state;

    const [wordTagCategory, wordTagType] = wordType.split("/");

    let paramNewWord;
    if (lexiconData) {
      paramNewWord = {
        word: lexiconData.form_data.forms.lemma || newWord,
        lexiconData: lexiconData,
      };
    } else {
      paramNewWord = newWord;
    }
    const shouldTranslate =
      alwaysTranslate || translateType === RequestWordTranslateType.TRANSLATE;
    const shouldMachineTranslate =
      !alwaysTranslate &&
      translateType === RequestWordTranslateType.MACHINE_TRANSLATE;

    newRequestWord(
      paramNewWord,
      modalEnglishInputValue,
      wordTagCategory,
      wordTagType,
      shouldTranslate,
      shouldMachineTranslate,
      shouldPopulate,
      vocabularyRequestProductId,
      vocabularyRequestProductDescription,
      modalDescriptionInputValue,
      language,
      token,
      textualAppName,
      (hypernymData || {}).vocabulary_id
    ).then((response) => {
      if (!this.mounted) {
        return;
      }
      const data = response.data;
      if (data && data.value) {
        const newVocabularies = [...vocabularies];
        newVocabularies.push(data);
        this.setState(
          {
            vocabularies: newVocabularies,
          },
          () => {
            if (!disableAutoAdd) {
              this._onAddVocabulary({
                label: formatTag(data, {
                  includeSubTagNames: true,
                  showTagTypeHints: showTagTypeHints,
                }),
                value: this.getValueWithTagId(data),
                type: data.type,
              });
            }
          }
        );
      }
      store.dispatch(
        setDjangoToastOpen({
          content: RequestWordTranslateTypeToastMessage[translateType],
          appearance: NotificationAppearance.SUCCESS,
        })
      );
    });

    this.closeRequestModal();
  };

  // --  EVENT HANDLERS  --
  _onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    this.setState({
      inputValue: text,
    });

    if (text.trim()) {
      this.setState({ isLoadingVocabulary: true });
      this.loadVocabulariesDebounce(text);
    }
  };

  _onFocus = (): void => {
    this.setState({ isInputFocused: true });
  };

  _onBlur = (): void => {
    const { activeSuggestion } = this.state;
    activeSuggestion >= 0
      ? setTimeout(
          () => this.setState({ isInputFocused: false, activeSuggestion: -1 }),
          150
        )
      : this.setState({ isInputFocused: false });
  };

  _onAddVocabulary = (suggestion: Suggestion, isEnterPressed = false): void => {
    const {
      isMultiple,
      onDataChanged,
      onVocabularyAdded,
      selectedTags,
    } = this.props;
    const { inputValue, vocabularies } = this.state;

    if (suggestion.value === "Not-found-value") {
      this.handleRequestWord(inputValue);
    } else {
      const selectedVocabularyIndex =
        vocabularies && vocabularies.length > 0
          ? vocabularies.findIndex((vocabulary) => {
              const matchedVocabularyValue = this.getValueWithTagId(vocabulary);
              return matchedVocabularyValue === suggestion.value;
            })
          : -1;
      if (selectedVocabularyIndex > -1) {
        const vocabulary = vocabularies[selectedVocabularyIndex];
        if (onVocabularyAdded) {
          onVocabularyAdded(vocabulary);
        }
        if (isMultiple) {
          const selectedVocabularies = [...selectedTags];
          selectedVocabularies.push(vocabulary);
          if (onDataChanged) {
            onDataChanged(this, selectedVocabularies);
          }
        } else {
          if (onDataChanged) {
            onDataChanged(this, vocabulary);
          }
        }
      }
    }

    this.setState({
      inputValue: "",
      isInputFocused: isEnterPressed,
      isLoadingVocabulary: false,
      suggestions: [],
      activeSuggestion: -1,
    });
  };

  _onRemoveTag = (
    event: React.MouseEvent<HTMLDivElement>,
    tag: VocabularyTreeData
  ): void => {
    event.stopPropagation();
    const { onDataChanged, onVocabularyRemoved, selectedTags } = this.props;

    const removeTagIndex =
      selectedTags && selectedTags.length > 0
        ? selectedTags.findIndex((vocabulary) => {
            const matchedVocabularyValue = this.getValueWithTagId(vocabulary);
            return matchedVocabularyValue === this.getValueWithTagId(tag);
          })
        : -1;
    if (removeTagIndex > -1) {
      const newVocabularies = [...selectedTags];
      newVocabularies.splice(removeTagIndex, 1);
      if (onDataChanged) {
        onDataChanged(this, newVocabularies);
      }
      if (onVocabularyRemoved) {
        onVocabularyRemoved(tag);
      }
    }
  };

  _onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const {
      isMultiple,
      selectedValue,
      selectedTags,
      onDataChanged,
      onVocabularyAdded,
    } = this.props;
    const {
      inputValue,
      isInputFocused,
      activeSuggestion,
      suggestions,
    } = this.state;

    if (isInputFocused) {
      switch (e.keyCode) {
        case 8: // backspace
          if (isMultiple) {
            if (inputValue === "" && selectedTags.length > 0) {
              const newVocabularies = [...selectedTags];
              newVocabularies.splice(selectedTags.length - 1, 1);
              if (onVocabularyAdded) {
                onVocabularyAdded(newVocabularies);
              }
              if (onDataChanged) {
                onDataChanged(this, newVocabularies);
              }
            }
          } else {
            if (inputValue === "" && selectedValue !== "") {
              if (onDataChanged) {
                onDataChanged(this, {});
              }
            }
          }
          break;
        case 13: // enter
          if (suggestions.length > 0) {
            this._onAddVocabulary(suggestions[activeSuggestion], true);
          }
          break;
        case 38: // arrowUp : update suggestion list selection index
          this.scrollToFocusedOptionOnUpdate = true;
          if (activeSuggestion === 0) {
            this.setState({
              activeSuggestion: suggestions.length - 1,
            });
            break;
          }
          this.setState({ activeSuggestion: activeSuggestion - 1 });
          break;
        case 40: // arrowDown : update suggestion list selection index
          this.scrollToFocusedOptionOnUpdate = true;
          if (activeSuggestion + 1 >= suggestions.length) {
            this.setState({ activeSuggestion: 0 });
            break;
          }
          this.setState({ activeSuggestion: activeSuggestion + 1 });
          break;
        default:
          break;
      }
    }
  };

  // singleInput catch selected-value hover moment for productDetail
  _onMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const textGrid = document.getElementsByClassName("texts-grid")[0];
    if (this.selectedValueRef && textGrid) {
      const valueRect = this.selectedValueRef.getBoundingClientRect();

      if (
        valueRect.left < e.clientX &&
        e.clientX < valueRect.right &&
        valueRect.top < e.clientY &&
        e.clientY < valueRect.bottom
      ) {
        if (!textGrid.className.includes("fade")) {
          textGrid.className += " fade";
        }
      } else {
        if (textGrid.className.includes("fade")) {
          textGrid.className = textGrid.className.replace(" fade", "");
        }
      }
    }
  };

  // --  RENDER METHODS  --

  renderSingleInput(): React.ReactElement {
    const { detailedVocabSuggestion, placeholder, selectedValue } = this.props;
    const { inputValue, isLoadingVocabulary } = this.state;

    let sizerValue = selectedValue ? selectedValue : "Start typing to search";
    if (inputValue && inputValue.length > sizerValue.length) {
      sizerValue = inputValue;
    }

    const inputPlaceholder = placeholder
      ? placeholder
      : "Start typing to search";

    return (
      <div className="input-wrapper">
        {selectedValue && inputValue === "" && !isLoadingVocabulary && (
          <div
            ref={(ref): void => {
              this.selectedValueRef = ref;
            }}
            className="selected-value"
          >
            {selectedValue}
          </div>
        )}
        <input
          onBlur={this._onBlur}
          data-testid={"single-input"}
          className="single-input"
          data-test-detailed-vocab={detailedVocabSuggestion}
          onChange={this._onChange}
          onFocus={this._onFocus}
          onMouseMove={this._onMouseMove}
          placeholder={selectedValue ? "" : inputPlaceholder}
          ref={(ref): void => {
            this.inputRef = ref;
          }}
          value={inputValue}
        />
        <div
          ref={(ref): void => {
            this.sizer = ref;
          }}
          className="sizer-style"
        >
          {sizerValue}
        </div>
      </div>
    );
  }

  renderMultipleInput(): React.ReactElement {
    const {
      placeholder,
      showTagTypeHints,
      selectedTags,
      showVocabType,
    } = this.props;
    const { inputValue } = this.state;
    let sizerValue = selectedTags.length > 0 ? "" : "Start typing to search";
    if (inputValue && inputValue.length > sizerValue.length) {
      sizerValue = inputValue;
    }
    const inputPlaceholder = placeholder
      ? placeholder
      : "Start typing to search";

    const selectedTagViews = selectedTags
      ? selectedTags.map((tag, index) => {
          sizerValue += formatTag(tag, {
            includeSubTagNames: true,
            showTagTypeHints: showTagTypeHints,
            showVocabType: showVocabType,
          });

          return (
            <Label
              as="div"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              data-vocab-id={tag.vocabulary_id}
              color="black"
              basic
              className="small-border"
            >
              {formatTag(tag, {
                includeSubTagNames: true,
                showTagTypeHints: showTagTypeHints,
                showVocabType: showVocabType,
              })}
              <Icon
                data-testid={"tag-remove"}
                name="delete"
                onClick={(
                  e: React.MouseEvent<HTMLDivElement, MouseEvent>
                ): void => this._onRemoveTag(e, tag)}
              />
            </Label>
          );
        })
      : [];

    return (
      <div
        className="input-wrapper"
        onClick={(): void => {
          if (this.multiInputRef) {
            this.multiInputRef.focus();
          }
        }}
      >
        {selectedTagViews}
        <input
          ref={(ref): void => {
            this.multiInputRef = ref;
          }}
          data-testid="multi-input"
          className="multi-input"
          placeholder={selectedTags.length > 0 ? "" : inputPlaceholder}
          onChange={this._onChange}
          onFocus={this._onFocus}
          value={inputValue}
          style={{ width: 10 }}
        />
        <div className="sizer-style multi-sizer-style">{sizerValue}</div>
      </div>
    );
  }

  renderSuggestionsContent(suggestion: Suggestion): React.ReactElement {
    const { detailedVocabSuggestion } = this.props;
    const pendingSuggestion =
      detailedVocabSuggestion &&
      suggestion.vocabularyRequestStatus === "Pending" &&
      "pending";
    return (
      <div
        className={
          suggestion.value === "Not-found-value"
            ? "option-request-word"
            : pendingSuggestion
        }
      >
        {detailedVocabSuggestion ? (
          <>
            {suggestion.label} / {suggestion.swedish} ({suggestion.vocabularyId}
            )
            <div>
              {suggestion.category} / {suggestion.type}
            </div>
          </>
        ) : (
          suggestion.label
        )}
      </div>
    );
  }

  renderSuggestions(): React.ReactElement {
    const { suggestions, isLoadingVocabulary, activeSuggestion } = this.state;
    const options = suggestions
      ? suggestions.map((suggestion, index) => {
          let className = "menu-item";
          if (index === activeSuggestion) {
            className += " suggestion-active";
          }
          if (suggestion.value !== "Not-found-value") {
            className += " suggestion-item";
          }
          return (
            <div
              ref={(ref): void => {
                if (className.includes("suggestion-active")) {
                  this.focusedOptionRef = ref;
                }
              }}
              key={suggestions.indexOf(suggestion)}
              className={className}
              onClick={(): void => this._onAddVocabulary(suggestion)}
              onMouseEnter={(): void =>
                this.setState({ activeSuggestion: index })
              }
            >
              {this.renderSuggestionsContent(suggestion)}
            </div>
          );
        })
      : [];

    return (
      <div
        className="suggestions-container"
        data-testid="suggestions-container"
      >
        <div
          ref={(ref): void => {
            this.menuRef = ref;
          }}
        >
          {suggestions && suggestions.length > 0 ? (
            options
          ) : isLoadingVocabulary ? (
            <div className="placeholder">Loading...</div>
          ) : (
            <div className="placeholder">No options</div>
          )}
        </div>
      </div>
    );
  }

  render(): React.ReactElement {
    const {
      alwaysTranslate,
      showTranslate,
      showMachineTranslate,
      inlineAutogrow,
      isMultiple,
      language,
      lexiconEditorDirection,
      selectedValue,
      selectedTags,
      showHypernym,
      textualAppName,
      useLexiconEditor,
    } = this.props;
    const {
      isLoadingVocabulary,
      isInputFocused,
      modalDescriptionInputValue,
      modalEnglishInputValue,
      wordType,
      shouldPopulate,
      isOpenRequestModal,
      newWord,
      inputWidth,
    } = this.state;

    const containerClassName = isInputFocused
      ? "input-container input-focused"
      : "input-container";
    let crossIcon = null;
    if (
      (isMultiple && selectedTags.length > 0) ||
      (!isMultiple && selectedValue !== "")
    ) {
      crossIcon = (
        <div className="icon-wrapper" onClick={this._removeSelections}>
          <CrossIcon size={20} />
        </div>
      );
    }

    return (
      <div
        ref={(ref): void => {
          this.vocabularyLookupRef = ref;
        }}
        className="vocabulary-lookup"
        style={inlineAutogrow ? { width: inputWidth } : {}}
        onKeyDown={this._onKeyDown}
      >
        <div className={containerClassName}>
          {isMultiple ? this.renderMultipleInput() : this.renderSingleInput()}
          <div className="indicator-container">
            {isLoadingVocabulary ? <LoadingIndicator /> : crossIcon}
          </div>
        </div>
        {isInputFocused && this.renderSuggestions()}

        {isOpenRequestModal && (
          <RequestWordModal
            alwaysTranslate={alwaysTranslate}
            closeModal={this.closeRequestModal}
            customerLanguage={language}
            description={modalDescriptionInputValue}
            englishTranslation={modalEnglishInputValue}
            isOpenModal={isOpenRequestModal}
            lexiconEditorDirection={lexiconEditorDirection}
            onDescriptionInputChange={(value): void => {
              this.setState({ modalDescriptionInputValue: value });
            }}
            onEnglishTranslationInputChange={(value): void => {
              this.setState({ modalEnglishInputValue: value });
            }}
            onHypernymChange={this.onHypernymChange}
            onLexiconDataChange={this.onLexiconDataChange}
            onShouldPopulateChange={this.onShouldPopulateChange}
            onWordTypeChange={this.onWordTypeChange}
            populateTranslations={true}
            providedNewWord={newWord}
            providedWordType={wordType}
            shouldPopulate={shouldPopulate}
            showHypernym={showHypernym}
            showTranslate={showTranslate}
            showMachineTranslate={showMachineTranslate}
            submitAction={this.requestNewWord}
            textualAppName={textualAppName}
            useLexiconEditor={useLexiconEditor}
            onAddCopiedVocabulary={this.onAddCopiedVocabulary}
          />
        )}
      </div>
    );
  }
}

export const VocabularyLookupNewComp = connector(VocabularyLookupNew);
/*
 *  --  Additional resources  --
 *  CrossIcon & LoadingIndicator
 */

const ClickableSvg = styled.svg`
  cursor: pointer;
`;

type SvgProps = {
  size: number;
};

const Svg = ({
  size,
  ...props
}: PropsWithChildren<SvgProps>): React.ReactElement => {
  return (
    <ClickableSvg
      height={size}
      width={size}
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="icon-svg"
      {...props}
    />
  );
};

export const CrossIcon = (
  props: PropsWithChildren<SvgProps>
): React.ReactElement => (
  <Svg {...props}>
    <path
      d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697
        0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697
        0-0.469-0.469-0.469-1.229
        0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228
        0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469
        1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758
        3.15c0.469 0.469 0.469 1.229 0 1.698z"
    />
  </Svg>
);

const LoadingDot = ({
  color,
  delay,
  offset,
}: {
  color: string;
  delay: number;
  offset: boolean;
}): React.ReactElement => (
  <span
    style={{
      animationDuration: "1s",
      animationDelay: `${delay}ms`,
      animationIterationCount: "infinite",
      animationName: "loading-animation",
      animationTimingFunction: "ease-in-out",
      backgroundColor: color,
      borderRadius: "1em",
      display: "inline-block",
      marginLeft: offset ? "1em" : null,
      height: "1em",
      verticalAlign: "top",
      width: "1em",
    }}
  />
);

export const LoadingIndicator = (): React.ReactElement => {
  return (
    <div className="loading-indicator">
      <LoadingDot color={"hsl(0, 0%, 20%)"} delay={0} offset={false} />
      <LoadingDot color={"hsl(0, 0%, 20%)"} delay={160} offset />
      <LoadingDot color={"hsl(0, 0%, 20%)"} delay={320} offset />
    </div>
  );
};
