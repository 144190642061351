import React, { useEffect, useMemo, useRef, useState } from "react";
import { Job, JobSummary } from "./types";
import { Tab } from "../../components/Tab";
import { JobSummaryTable } from "./JobSummaryTable";
import { useUrlState } from "../../utils/react-custom-hooks/urlStateHandler";

const BASE_SCROLL_SIZE = 40;
const SCROLL_SIZE_INCREMENT = 10;
type Props = {
  job: Job;
  retryClicked: boolean;
  OnRetryFailedDocuments: (ids: string[]) => void;
};

export const JobsSummaryTableTabs: React.FC<Props> = ({
  job,
  retryClicked,
  OnRetryFailedDocuments,
}) => {
  const {
    setParam,
    removeParam,
    urlState: { q },
  } = useUrlState<{
    q: string;
  }>();

  const cleanQ = useMemo(() => {
    if (!q) return "";
    return q.replace(/"/g, "");
  }, [q]);

  const [currentScrollSize, setCurrentScrollSize] = useState(BASE_SCROLL_SIZE);

  const observer = useRef<IntersectionObserver>();
  const scrollElement = useRef<HTMLDivElement>(null);
  const mounted = useRef(false);

  const resetScrollPosition = (): void => {
    setCurrentScrollSize(BASE_SCROLL_SIZE);
    if (scrollElement.current) {
      scrollElement.current.scrollTo({ top: 0 });
    }
  };

  const observeLastElementInList = (element: Element): void => {
    if (!mounted.current) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((elements) => {
      if (elements[0].isIntersecting) {
        setCurrentScrollSize((prevState) => prevState + SCROLL_SIZE_INCREMENT);
      }
    });
    if (element) observer.current.observe(element);
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { summary } = job;

  const filteredSummary = (summaries: JobSummary[]): JobSummary[] => {
    if (!summaries) return [];
    let filteredSummary = summaries;
    if (cleanQ) {
      const qLower = cleanQ.toLowerCase();
      filteredSummary = filteredSummary.filter((summary) => {
        return (
          summary.last_report.resource_id
            .toString()
            .toLowerCase()
            .includes(qLower) ||
          summary.last_report.resource_name?.toLowerCase().includes(qLower) ||
          summary.last_report.related_resources?.some(
            (r) =>
              r.resource_id.toString().toLowerCase().includes(qLower) ||
              r.resource_name?.toLowerCase().includes(qLower)
          )
        );
      });
    }
    return filteredSummary.slice(0, currentScrollSize);
  };

  const filteredSuccessSummary = useMemo(() => {
    const successSummary = Object.values(summary).filter(
      (summary) => !("reason" in summary.last_report)
    );

    return filteredSummary(successSummary);
  }, [summary, currentScrollSize, cleanQ]);

  const filteredFailuresSummary = useMemo(() => {
    const failureSummary = Object.values(summary).filter(
      (summary) => "reason" in summary.last_report
    );
    return filteredSummary(failureSummary);
  }, [summary, currentScrollSize, cleanQ]);

  const hasActiveFilter = !!cleanQ;

  const panes = useMemo(() => {
    return [
      {
        menuItem: {
          key: "successes",
          heading: "Successes",
          subHeading: `${filteredSuccessSummary.length} ${
            hasActiveFilter ? "(Filtered)" : ""
          }`,
        },
        content: (
          <JobSummaryTable
            summary={filteredSuccessSummary}
            loadingRetry={retryClicked}
            OnRetryFailedDocuments={OnRetryFailedDocuments}
            observeLastElementInList={observeLastElementInList}
            scrollElement={scrollElement}
          />
        ),
      },
      {
        menuItem: {
          key: "failures",
          heading: "Failures",
          subHeading: `${filteredFailuresSummary.length} ${
            hasActiveFilter ? "(Filtered)" : ""
          }`,
        },
        content: (
          <JobSummaryTable
            summary={filteredFailuresSummary}
            loadingRetry={retryClicked}
            OnRetryFailedDocuments={OnRetryFailedDocuments}
            observeLastElementInList={observeLastElementInList}
            scrollElement={scrollElement}
          />
        ),
      },
    ];
  }, [filteredSuccessSummary, filteredFailuresSummary, retryClicked]);

  return (
    <div className="tw-ml-2">
      <form className="txu-form tw-flex tw-flex-row tw-gap-2 !tw-mb-4 !tw-mt-4">
        <div className="tw-flex tw-flex-col tw-flex-grow">
          <label className="tw-text-sm tw-text-gray-500">Search</label>
          <input
            type="text"
            placeholder="Search by Resource ID, Name..."
            className="txu-input tw-w-full"
            onChange={({ target }) => {
              resetScrollPosition();
              const { value } = target;
              if (!value) {
                removeParam("q");
                return;
              }
              setParam("q", `"${value}"`);
            }}
            value={cleanQ}
          />
        </div>
      </form>
      <small className="tw-text-gray-500">
        Total Resources: {Object.keys(summary).length}
      </small>
      <Tab panes={panes} />
    </div>
  );
};
