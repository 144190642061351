import { Job, OurJobStatus } from "./types";
import { Option } from "../../components/tailwind/dropdown/Dropdown";

const friendlyJobStatusMap: Record<OurJobStatus, string> = {
  PENDING: "Pending",
  PROGRESS: "Progress",
  FAILED: "Failed",
  SUCCESS: "Success",
  PARTIALLY_FAILED: "Partially Failed",
  NO_PARENT_TASK: "No Parent Task",
  UNDETERMINED: "Undetermined",
};

export function friendlyStatus(status: OurJobStatus): string {
  return friendlyJobStatusMap[status];
}

export const jobStatusDropdownItems: Option<OurJobStatus>[] = Object.entries(
  friendlyJobStatusMap
).map(([key, value]) => ({
  key,
  value: key as OurJobStatus,
  text: value,
}));

export const findRetryableDocumentIds = (job: Job): string[] => {
  const summary = job?.summary;
  if (!summary) return [];
  return Object.values(summary)
    .filter(
      (row) =>
        row.last_report?.resource_type === "Product" &&
        "reason" in row.last_report
    )
    .map((row) => row.last_report.resource_id as string);
};
