import React, { useMemo } from "react";

const sliceRange = (
  range: number[],
  currentPage: number,
  size: number
): number[] => {
  const maxNumber = range.length;
  if (currentPage - 1 <= size / 2) {
    return range.slice(0, size);
  }
  if (currentPage - 1 >= maxNumber - size / 2) {
    return range.slice(-size);
  }
  return range.slice(currentPage - size / 2, currentPage + size / 2);
};

type Props = {
  currentPage: number;
  totalPages: number;
  paginatorSize: number;
  handlePageChange: (page: number) => void;
};

export const JobsPaginator: React.FC<Props> = ({
  currentPage,
  totalPages,
  paginatorSize,
  handlePageChange,
}) => {
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  const handlePreviousPage = (): void => {
    if (!hasPreviousPage) return;
    handlePageChange(currentPage - 1);
  };

  const handleNextPage = (): void => {
    if (!hasNextPage) return;
    handlePageChange(currentPage + 1);
  };

  const displayPages = useMemo(() => {
    return sliceRange(
      Array.from({ length: totalPages }, (_, index) => index + 1),
      currentPage,
      paginatorSize
    );
  }, [totalPages, currentPage, paginatorSize]);

  const isPaginatorVisible = displayPages.length > 1;

  return (
    <ul
      className={`txu-logs-paginator ${
        isPaginatorVisible ? "" : "tw-invisible"
      }`}
    >
      <li className="txu-arrow">
        {hasPreviousPage ? (
          <a onClick={handlePreviousPage} className="tw-cursor-pointer">
            <i className="material-icons">arrow_back</i>
          </a>
        ) : (
          <span>
            <i className="material-icons">arrow_back</i>
          </span>
        )}
      </li>
      {displayPages.map((page) => (
        <li key={page}>
          <a
            className={`txu-page-number tw-cursor-pointer ${
              page === currentPage ? "txu-current-page" : ""
            }`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </a>
        </li>
      ))}
      <li className="txu-arrow">
        {hasNextPage ? (
          <a onClick={handleNextPage} className="tw-cursor-pointer">
            <i className="material-icons">arrow_forward</i>
          </a>
        ) : (
          <span>
            <i className="material-icons">arrow_forward</i>
          </span>
        )}
      </li>
    </ul>
  );
};
