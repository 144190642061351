import { Job, OurJobStatus, ParentTask } from "../customers/jobs/types";
import api from "./api";

export const JOBS_BASE_URL = "/api/_internal/customer/jobs";

export async function getCustomerJobs(
  token: string,
  page: number = 1,
  q?: string,
  status?: OurJobStatus,
  includeTasks: boolean = false,
  pageSize: number = 10
): Promise<{ jobs: Job[]; total_pages: number; current_page: number }> {
  const response = await api.get<{
    jobs: Job[];
    total_pages: number;
    current_page: number;
  }>(JOBS_BASE_URL, {
    headers: { token },
    params: {
      page,
      page_size: pageSize,
      q,
      status,
      include_tasks: includeTasks ? "1" : "0",
    },
  });
  return response.data;
}

export async function getCustomerJobDetail(
  token: string,
  jobId: string
): Promise<Job> {
  const response = await api.get(`${JOBS_BASE_URL}/${jobId}`, {
    headers: { token },
  });
  return response.data;
}

export async function refreshTasksStatus(
  token: string,
  jobId: number
): Promise<ParentTask[]> {
  const response = await api.post(
    `${JOBS_BASE_URL}/refresh-status/tasks`,
    { job_id: jobId },
    {
      headers: { token },
    }
  );
  return response.data;
}

export async function refreshJobsStatus(
  token: string,
  jobIds: number[]
): Promise<{ [key: number]: OurJobStatus }> {
  const response = await api.post(
    `${JOBS_BASE_URL}/refresh-status/jobs`,
    { job_ids: jobIds },
    { headers: { token } }
  );
  return response.data;
}

export async function retryJob(
  token: string,
  jobId: number,
  productIds: string[]
): Promise<void> {
  const response = await api.post(
    `${JOBS_BASE_URL}/retry`,
    {
      job_id: jobId,
      product_ids: productIds,
    },
    {
      headers: { token },
    }
  );
  return response.data;
}
