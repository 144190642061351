import React from "react";
import { Job, ParentTask } from "./types";
import { TaskProgressbar } from "./TaskProgressbar";
import { Spinner } from "../../utils/Spinner";

const PLACEHOLDER_TASK: ParentTask = {
  task_id: "placeholder",
  status: "PENDING",
  status_from_result: "PENDING",
  progress: {
    total: 100,
    success: 0,
    error: 0,
  },
  reports: {
    success: [],
    error: [],
    total: 100,
  },
  created: new Date().toISOString(),
  updated: new Date().toISOString(),
  done: new Date().toISOString(),
  result: {},
  children: [],
  failed_product_list_url: null,
};

type Props = {
  job: Job;
  fetchingJob: boolean;
  retryClicked: boolean;
};

export const JobProgressbarHandler: React.FC<Props> = ({
  job,
  fetchingJob,
  retryClicked,
}) => {
  if (fetchingJob) {
    return (
      <div className="txu-log-item-value">
        <Spinner align="left" />
      </div>
    );
  }

  const { running_tasks, last_run_task } = job;

  if (running_tasks.length > 0) {
    return (
      <div className="txu-log-item-value tw-flex tw-flex-col tw-gap-2">
        {running_tasks.map((task) => (
          <TaskProgressbar key={task.task_id} task={task} />
        ))}
      </div>
    );
  }

  if (retryClicked) {
    return (
      <div className="txu-log-item-value">
        <TaskProgressbar task={PLACEHOLDER_TASK} isPlaceholder />
      </div>
    );
  }
  if (last_run_task) {
    return (
      <div className="txu-log-item-value">
        <TaskProgressbar task={last_run_task} />
      </div>
    );
  }

  return (
    <div className="txu-log-item-value">
      <p className="tw-text-gray-500 tw-text-xs">No progress to report</p>
    </div>
  );
};
