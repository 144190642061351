import $ from "jquery";
import { getProductTextCount } from "../api/action";
import { ChannelLanguagePairData } from "../products/product";
import { LanguageCode } from "../customers/customerlanguages";
import { showMessage } from "../utils/partialUtils";
import { NotificationAppearance } from "../api/djangoToastSlice";

// ACTION -> PUBLISH TO CONNECTOR
// ACTION -> UPDATE CHANNEL LANGUAGE PAIRS
// Select all languages
$(document).on("click", ".select-all", function () {
  $("#language-choices").find("input[type=checkbox]").prop("checked", true);
});

// Deselect all languages
$(document).on("click", ".deselect-all", function () {
  $("#language-choices").find("input[type=checkbox]").prop("checked", false);
});

// ACTION -> CHANGE STATUS
// Show warning when changing product status to importing
$(document).on("change", "#id_new_status", function () {
  const warning = $("#status_change_warning");
  $(this).val() === "I" ? warning.show() : warning.hide();
});

// ACTION -> GENERATE TEXTS
const countProductText = (): void => {
  const productTextCountSpan = $("#product-text-count");
  if (productTextCountSpan.length === 0) {
    return;
  }
  productTextCountSpan.text("counting...");

  const token = $('input[name="token"]').val().toString();
  const selectionBucketName = $('input[name="bucket"]').val().toString();

  const shouldSelectAllPairs = $("input#select-all-languages-and-channels").is(
    ":checked"
  );
  let channelLanguagePairs: ChannelLanguagePairData[] | null;
  if (shouldSelectAllPairs) {
    channelLanguagePairs = null;
  } else {
    channelLanguagePairs = [];
    $(".channel-language-pair").each(function () {
      channelLanguagePairs.push({
        language_code: $(this).attr("data-language") as LanguageCode,
        channel_id: parseInt($(this).attr("data-channel-id")),
      });
    });
  }

  const overrideApproved = $("#regenerate_approved").is(":checked");
  const overrideEdited = $("#regenerate_edited").is(":checked");
  const overridePublished = $("#regenerate_published").is(":checked");

  (async (): Promise<any> => {
    const count = await getProductTextCount(
      token,
      selectionBucketName,
      channelLanguagePairs,
      overrideApproved,
      overrideEdited,
      overridePublished
    );
    productTextCountSpan.text(`${count} text(s).`);
  })();
};

// REFRESH TASK STATUS
const getRefreshStartTime = (): number => {
  const urlParams = new URLSearchParams(window.location.search);
  const taskId = urlParams.get("task_id");
  if (taskId) {
    const refreshStartTime = sessionStorage.getItem(taskId);
    if (refreshStartTime) {
      return parseInt(refreshStartTime);
    } else {
      const refreshStartTime = Date.now();
      sessionStorage.setItem(taskId, refreshStartTime.toString());
      return refreshStartTime;
    }
  }
};
const getRefreshTimeout = (): number => {
  const refreshStartTime = getRefreshStartTime();
  const elapsedTime = Math.round((Date.now() - refreshStartTime) / 1000); // seconds
  let timeout = 2 * 1000; // milliseconds
  if (elapsedTime > 60) {
    timeout = 5 * 1000;
  }
  if (elapsedTime > 300) {
    timeout = 15 * 1000;
  }
  if (elapsedTime > 600) {
    timeout = 30 * 1000;
  }
  return timeout;
};
const refreshUploadTaskPage = (): void => {
  const uploadTask = $(".upload-task");
  const autoRedirect = !window.location.search.includes("redirect=False"); // Prevent redirect when done; see see BulkActionTaskView.PARAM_AUTO_REDIRECT
  if (uploadTask.length) {
    const response = $.ajax({
      url: uploadTask[0].getAttribute("data-url"),
      success: function () {
        const htmlResponse = $.parseHTML(response.responseText);
        const taskStatus = $(htmlResponse).find("#task-status");
        if (taskStatus) {
          const status = taskStatus.html()?.trim();
          uploadTask.find("#task-dialog").replaceWith(response.responseText);
          if (status === "PROGRESS" || status === "PENDING") {
            setTimeout(refreshUploadTaskPage, getRefreshTimeout());
          } else if (status === "SUCCESS" || status === "CANCELLED") {
            if (status === "SUCCESS") {
              const retryUrl = uploadTask.find("input[name='retry_url']").val();
              if (retryUrl) {
                window.location.href = retryUrl.toString();
                return;
              }
            }
            // Reload when task is complete
            if (autoRedirect) {
              window.location.reload();
            }
          } else {
            showMessage(
              NotificationAppearance.ERROR,
              "An unexpected error occurred."
            );
          }
        }
      },
      error: function (jqXHR) {
        if (jqXHR.status === 503 && autoRedirect) {
          window.location.reload();
        } else {
          showMessage(
            NotificationAppearance.ERROR,
            "An unexpected error occurred."
          );
        }
      },
    });
  }
};

$(function () {
  countProductText();
  refreshUploadTaskPage();
});

$(document).on("change", ".regenerate", () => countProductText());

$(document).on("click", 'input[name="selection"]', (event) => {
  if (event.target.value === "product-list") {
    $(".generate-summary").removeClass("greyed-out");
  } else {
    $(".generate-summary").addClass("greyed-out");
  }
  const isViewedInManagePipeline = event.target.form?.classList.contains(
    "manage-pipeline-action-step-form"
  );
  if (!isViewedInManagePipeline) {
    countProductText();
  }
});

$(document).on("change", "#id_exporter", (event) => {
  const defaultExporterId = $("#default-exporter-id")?.val();
  const defaultExporterOptions = $("#default-exporter-options");
  if (!defaultExporterId || !defaultExporterOptions) return;
  if (event.target.value === defaultExporterId) {
    defaultExporterOptions.removeClass("tw-hidden");
  } else {
    defaultExporterOptions.addClass("tw-hidden");
  }
});
