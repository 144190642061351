import React, { useRef } from "react";
import { JobSummary } from "./types";
import { Button } from "../../components/tailwind";

type Props = {
  summary: JobSummary;
  isLastElement: boolean;
  observeLastElementInList: (ref: HTMLTableRowElement) => void;
  loadingRetry: boolean;
  OnRetryFailedDocuments: (ids: string[]) => void;
};

export const JobSummaryTableRow: React.FC<Props> = ({
  summary,
  isLastElement,
  observeLastElementInList,
  loadingRetry,
  OnRetryFailedDocuments,
}) => {
  const { last_report: report } = summary;

  const relatedResourcesRow = useRef<HTMLTableRowElement>(null);

  const toggleRelatedResources = (): void => {
    if (relatedResourcesRow.current) {
      relatedResourcesRow.current.classList.toggle("tw-hidden");
    }
  };

  const isFailed = "reason" in report;

  return (
    <>
      <tr
        ref={(ref) => isLastElement && observeLastElementInList(ref)}
        {...(loadingRetry && {
          className: "tw-animate-pulse",
        })}
      >
        <td>{report.resource_type.replace("Product", "Document")}</td>
        <td>
          {report.resource_name || ""} <br />
          <span className="tw-text-gray-500 tw-text-xs">
            {report.resource_id}
          </span>
        </td>
        <td className="tw-max-w-[300px]">
          {isFailed ? report.reason || "-" : "-"}
        </td>
        <td>
          {report.resource_url ? (
            <a
              className="txu-btn txu-compact txu-secondary-alt txu-sm"
              href={report.resource_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Resource...
            </a>
          ) : (
            <span className="tw-text-gray-500 tw-text-xs">
              No resource link
            </span>
          )}
        </td>
        <td>
          {report.related_resources ? (
            <Button size="sm" compact onClick={toggleRelatedResources}>
              View Related Resources
            </Button>
          ) : (
            <span className="tw-text-gray-500 tw-text-xs">
              No related resources
            </span>
          )}
        </td>
        <td>
          <Button
            variant="primary"
            size="sm"
            compact
            disabled={!isFailed || loadingRetry}
            onClick={() => {
              OnRetryFailedDocuments([report.resource_id as string]);
            }}
          >
            Retry
          </Button>
        </td>
      </tr>

      <tr ref={relatedResourcesRow} className={"tw-hidden"}>
        <td colSpan={7}>
          <table className="txu-table txu-compact txu-center-all tw-bg-gray-100">
            <thead className="tw-sticky tw-top-12 tw-z-10 tw-bg-gray-100">
              <tr>
                <th>Resource</th>
                <th>Name / ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {report.related_resources?.map((r) => (
                <tr key={r.resource_id}>
                  <td>{r.resource_type.replace("Product", "Document")}</td>
                  <td>
                    {r.resource_name || ""} <br />
                    <span className="tw-text-gray-500 tw-text-xs">
                      {r.resource_id}
                    </span>
                  </td>
                  <td>
                    {r.resource_url ? (
                      <a
                        className="txu-btn txu-compact txu-secondary-alt txu-sm"
                        href={r.resource_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Resource...
                      </a>
                    ) : (
                      <span className="tw-text-gray-500 tw-text-xs">
                        No resource link
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};
