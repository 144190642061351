import React, { useLayoutEffect, useRef } from "react";
import { useContextMenu } from "./tailwind";
import { useSelectedText } from "../utils/reactCustomHooks";
import { LanguageCode } from "../customers/customerlanguages";
import {
  copyToClipboard,
  cutToClipboard,
  pasteFromClipboard,
  selectAllText,
} from "../utils/clipboardUtils";
import { AIRewriteContextMenuList } from "./AIRewriteContextMenu";

type Props = {
  value: string;
  onChange: (value: string) => void;
  languageCode: LanguageCode;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  "data-testid"?: string;
  getRef?: (ref: HTMLTextAreaElement) => void;
  onGenerationCompleteCallback?: (text: string) => void;
};

export const TextareaWithRewriteContextMenu: React.FC<Props> = ({
  value,
  onChange,
  languageCode,
  placeholder,
  disabled,
  id,
  getRef,
  onGenerationCompleteCallback,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>();
  const {
    setIsOpen: setOpenContextMenu,
    onContextMenu,
    ContextMenu,
    freeze,
    unFreeze,
    frozen,
  } = useContextMenu(textareaRef);
  const { selectedText, selectionPosition, onSelect } = useSelectedText(value);

  const closeContextMenu = (): void => {
    setOpenContextMenu(false);
  };

  useLayoutEffect(() => {
    if (textareaRef.current) {
      const [start, end] = selectionPosition;
      textareaRef.current.setSelectionRange(start, end);
    }
  }, [selectionPosition]);

  return (
    <>
      <textarea
        {...rest}
        id={id}
        ref={(el): void => {
          getRef?.(el);
          textareaRef.current = el;
        }}
        className="txu-textarea selection:tw-bg-yellow-300/50"
        value={value}
        onChange={(e): void => {
          onChange(e.target.value);
        }}
        onSelect={onSelect}
        onMouseDown={closeContextMenu}
        onContextMenu={onContextMenu}
        disabled={disabled || frozen}
        placeholder={placeholder}
      />
      <ContextMenu
        onCopy={(): void => {
          copyToClipboard(selectedText, !!selectedText).finally(() => {
            closeContextMenu();
          });
        }}
        onCut={(): void => {
          const [start, end] = selectionPosition;
          cutToClipboard(selectedText, value, start, end, !!selectedText)
            .then((newValue) => {
              onChange(newValue);
            })
            .finally(() => {
              closeContextMenu();
            });
        }}
        onPaste={(): void => {
          pasteFromClipboard((pastedText) => {
            const [start, end] = selectionPosition;
            const newValue =
              value.substring(0, start) + pastedText + value.substring(end);
            onChange(newValue);
            closeContextMenu();
          });
        }}
        onSelectAll={(): void => {
          selectAllText(textareaRef.current);
          closeContextMenu();
        }}
      >
        <AIRewriteContextMenuList
          getIsGenerating={(generating): void => {
            if (generating) {
              freeze();
            } else {
              unFreeze();
            }
          }}
          onGenerationCompleteCallback={(text): void => {
            let newValue = text;
            if (selectedText) {
              const [start, end] = selectionPosition;
              newValue =
                value.substring(0, start) + text + value.substring(end);
            }
            onGenerationCompleteCallback?.(newValue);
            closeContextMenu();
          }}
          input={selectedText || value}
          languageCode={languageCode}
        />
      </ContextMenu>
    </>
  );
};
