import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import debounce from "lodash/debounce";
import { useUrlState } from "../../utils/react-custom-hooks/urlStateHandler";
import { JobsPaginator } from "./JobsPaginator";
import { useSelector } from "react-redux";
import { getCustomerJobs, refreshJobsStatus } from "../../api/jobsApi";
import { RootState } from "../../utils/store";
import { Job, OurJobStatus } from "./types";
import { JobsTable } from "./JobsTable";
import { Dropdown } from "../../components/tailwind";
import { jobStatusDropdownItems } from "./utils";

// TEST PARAMS
const PAGINATOR_SIZE = 10;

export const Jobs: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const {
    urlState: { page, status, q },
    setParam,
    removeParam,
    settled,
  } = useUrlState<{
    page: number;
    status: OurJobStatus;
    q: string;
  }>({
    page: 1,
  });

  const [fetchingJobs, setFetchingJobs] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [jobs, setJobs] = useState<Job[]>([]);

  const pendingJobIds = useMemo(() => {
    return jobs
      .filter((job) =>
        ["PENDING", "PROGRESS"].includes(job.status_from_tasks_result)
      )
      .map((job) => job.id);
  }, [jobs]);

  const mounted = useRef(false);

  const getJobs = (page: number, q: string, status: OurJobStatus): void => {
    setFetchingJobs(true);
    getCustomerJobs(token, page, q, status)
      .then((res) => {
        if (res.current_page === page && mounted.current) {
          setTotalPages(res.total_pages);
          setJobs(res.jobs);
        }
      })
      .finally(() => {
        setFetchingJobs(false);
      })
      .catch(() => {
        handlePageChange(1);
      });
  };

  const debouncedGetJobs = useCallback(debounce(getJobs, 1000), []);

  useEffect(() => {
    if (!settled || !!jobs.length) return;
    getJobs(page, q, status);
  }, [settled, token, page]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handlePageChange = (page: number): void => {
    setParam("page", page);
    setFetchingJobs(true);
    debouncedGetJobs(page, q, status);
  };

  const updatePendingJobsStatus = async (): Promise<void> => {
    const updatedJobs = await refreshJobsStatus(token, pendingJobIds);
    setJobs((prevJobs) =>
      prevJobs.map((job) => ({
        ...job,
        status_from_tasks_result:
          updatedJobs?.[job.id] || job.status_from_tasks_result,
      }))
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (pendingJobIds.length > 0) {
        updatePendingJobsStatus();
      }
    }, 5000); // Pull new statuses every 5 seconds

    return () => clearInterval(interval as any);
  }, [pendingJobIds]);

  return (
    <>
      <h1>Jobs</h1>
      <form className="txu-form txu-logs-filter-form">
        <div className="tw-col-span-2">
          <input
            type="text"
            className="txu-input tw-w-full"
            placeholder="Search Job by ID, Invoked By..."
            value={q}
            onChange={({ target }) => {
              if (!target.value) {
                removeParam("q");
              } else {
                setParam("q", target.value);
              }
              setParam("page", 1);
              debouncedGetJobs(1, target.value || undefined, status);
            }}
          />
        </div>
        <div>
          <Dropdown<OurJobStatus>
            clearable
            options={jobStatusDropdownItems}
            onChange={(value) => {
              if (!value) {
                removeParam("status");
              } else {
                setParam("status", value as OurJobStatus);
              }
              setParam("page", 1);
              debouncedGetJobs(1, q, (value as OurJobStatus) || undefined);
            }}
            value={status}
            placeholder="Status"
          />
        </div>
      </form>
      <JobsPaginator
        currentPage={page}
        totalPages={totalPages}
        paginatorSize={PAGINATOR_SIZE}
        handlePageChange={handlePageChange}
      />
      <JobsTable fetchingJobs={fetchingJobs} jobs={jobs} />
      <JobsPaginator
        currentPage={page}
        totalPages={totalPages}
        paginatorSize={PAGINATOR_SIZE}
        handlePageChange={handlePageChange}
      />
    </>
  );
};
