import React from "react";
import { Job } from "./types";
import { Spinner } from "../../utils/Spinner";
import { friendlyStatus } from "./utils";
import { bulkActionLabels } from "../../ui/BulkActionsDropdown";

type JobTableRowProps = {
  job: Job;
  isLoading: boolean;
};

export const JobTableRow: React.FC<JobTableRowProps> = ({ job, isLoading }) => {
  const currentHref = window.location.href.split("?")[0];
  return (
    <tr
      {...(isLoading ? { className: "tw-animate-pulse tw-text-gray-500" } : {})}
    >
      <td>
        {isLoading ? (
          <>{job.id}</>
        ) : (
          <a href={`${currentHref}/${job.id}`}>{job.id}</a>
        )}
      </td>
      <td>{bulkActionLabels?.[job.action_name] || job.action_name}</td>
      <td>
        <div>{friendlyStatus(job.status_from_tasks_result)}</div>
      </td>
      <td>{job.created}</td>
    </tr>
  );
};

type JobsTableRowsProps = {
  jobs: Job[];
  notFound: boolean;
  isLoading: boolean;
};

export const JobsTableRows: React.FC<JobsTableRowsProps> = ({
  jobs,
  notFound,
  isLoading,
}) => {
  if (notFound && !isLoading)
    return (
      <tr>
        <td colSpan={4}>No jobs found</td>
      </tr>
    );
  if (isLoading && notFound) {
    return (
      <tr className="tw-animate-pulse">
        <td colSpan={4}>
          <Spinner />
        </td>
      </tr>
    );
  }
  return (
    <>
      {jobs.map((job) => (
        <JobTableRow key={job.id} job={job} isLoading={isLoading} />
      ))}
    </>
  );
};
