import React from "react";
import { Popup, PopupProps } from "semantic-ui-react";

import { Props as ButtonProps } from "../components/tailwind/button/Button";
import { TranslateButton } from "../components/TranslateButton";
import { LanguageCode } from "../customers/customerlanguages";
import { popupDelay } from "../customers/gpt/types";

type Props = Omit<ButtonProps, "onClick"> & {
  onTranslate: (languageCode: LanguageCode, configGroupId?: number) => void;
  languageCode: LanguageCode;
  popupProps?: PopupProps;
};

export const StatelessTranslateButton: React.FC<Props> = ({
  onTranslate,
  languageCode,
  popupProps = { disabled: true },
  ...rest
}) => {
  return (
    <Popup
      mouseEnterDelay={popupDelay}
      {...popupProps}
      trigger={
        <TranslateButton
          onClick={(configGroupId): void =>
            onTranslate(languageCode, configGroupId)
          }
          {...rest}
        />
      }
    />
  );
};
