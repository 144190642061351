import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Form, {
  Field,
  FieldTemplateProps,
  UiSchema,
} from "react-jsonschema-form";
import { Button, Dropdown, Grid, Label } from "semantic-ui-react";
import {
  DropdownOnSearchChangeData,
  DropdownProps,
} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import { guessLexiconForms, VocabularyId } from "../api/vocabularyApi";
import { LanguageCode } from "../customers/customerlanguages";
import { JSONSchema6 } from "json-schema";
import { DirectionProperty } from "csstype";
import { debounce } from "../utils/debounce";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { RootState, store } from "../utils/store";
import {
  setDjangoToastOpen,
  NotificationAppearance,
} from "../api/djangoToastSlice";
import { getFieldSpecification, searchFieldSpecification } from "../api/action";

export type LexiconEditorJSONSchema = JSONSchema6 & { list_id: number };

type LexiconFormsData = Record<string, string>;

const mapStateToProps = (state: RootState): { token: string | null } => ({
  /** The current authentication token, used to talk with the backend */
  token: state.auth.token,
});

const connector = connect(mapStateToProps, null);

// keep exact with SemanticName(Constant).slug
type TranslationType =
  | "descriptor"
  | "occasion"
  | "phrase"
  | "sentence"
  | "thing";

export type LexiconEditorFormData = {
  forms: LexiconFormsData;
  gf_category: string;
};

type FieldSpecification = {};

type DropdownSearchFieldProps = {
  formData: string;
  schema: LexiconEditorJSONSchema;
  onChange: (formData: any) => void;
};

const DropdownSearchField: React.FC<DropdownSearchFieldProps> = ({
  formData,
  schema,
  onChange,
}) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<FieldSpecification[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState(formData);

  useEffect(() => {
    let mounted = true;
    if (token) {
      (async (): Promise<void> => {
        await getFieldSpecification(token, formData)
          .then((suggestions) => {
            if (mounted) setOptions(suggestions);
          })
          .catch(() => {
            if (mounted) setOptions([]);
          })
          .finally(() => {
            if (mounted) setLoading(false);
          });
      })();
    }
    return () => {
      mounted = false;
    };
  }, [token]);

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ): void => {
    setData(value as string);
    onChange(formData);
  };

  const onSearchChange = (
    event: React.SyntheticEvent<HTMLElement>,
    value: DropdownOnSearchChangeData
  ): void => {
    if (value.searchQuery.length < 3) {
      return;
    }
    setLoading(true);
    searchFieldSpecification(token, schema.list_id, value.searchQuery)
      .then((suggestions) => {
        setOptions(suggestions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dropdown
      style={{ minWidth: "300px", width: "max-content" }}
      placeholder="Category"
      onChange={handleChange}
      onSearchChange={debounce(onSearchChange, 200)}
      search
      selection
      options={options}
      value={formData}
      loading={loading}
      upward={false}
      fluid
    />
  );
};

type Props = {
  direction: DirectionProperty;
  initialData: { form_data: LexiconEditorFormData };
  jsonSchema: LexiconEditorJSONSchema;
  typeOfTranslation?: TranslationType;
  languageCode: LanguageCode;
  onDataChanged?: (editor: LexiconEditor, data: any) => void;
  onLoaded?: (editor: LexiconEditor) => void;
  uiSchema: UiSchema;
  vocabularyId?: VocabularyId;
};

type State = {
  formData: LexiconEditorFormData;
  gfCategory: string;
};

type LexiconEditorProps = ConnectedProps<typeof connector> & Props;

const TextButton = styled.a``;

export default class LexiconEditor extends React.Component<
  LexiconEditorProps,
  State
> {
  private formDivRef = React.createRef<HTMLDivElement>();
  private mounted = false;

  constructor(props: LexiconEditorProps) {
    super(props);
    const { initialData } = this.props;
    this.state = {
      formData: initialData.form_data,
      gfCategory: (initialData.form_data || {}).gf_category,
    };
  }

  componentDidMount(): void {
    this.mounted = true;
    const { onLoaded } = this.props;
    onLoaded(this);
    const { formData } = this.state;
    this.onChange({ formData: formData });
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  onChange = ({ formData }: any): void => {
    const { onDataChanged } = this.props;
    const { gfCategory } = this.state;
    const newFormData = { ...formData };
    const oldGfCategory = gfCategory;
    const newGfCategory = (formData || {}).gf_category;

    // whenever the gf_category changes, change the type to 'default'
    newFormData.forms = newFormData.forms || {};
    if (oldGfCategory != newGfCategory) {
      newFormData.forms.type = "default";
    }
    newFormData.forms.type = newFormData.forms.type || "default";

    for (const key in newFormData.forms) {
      if (newFormData.forms[key]?.includes("\n")) {
        newFormData.forms[key] = newFormData.forms[key].replace(
          /(\s*)?\n(\s*)?/g,
          " "
        );
      }
    }

    // deep-copy object
    const finalFormData = JSON.parse(JSON.stringify(newFormData));
    /*
     * changing type to undefined, then back to the final type forces the
     * json schema library to re-evaluate the schema conditions
     */
    newFormData.forms.type = undefined;

    // 1. type = undefined
    this.setState({ formData: newFormData, gfCategory: newGfCategory });

    // 2. type = final type
    setTimeout(() => {
      if (this.mounted) this.setState({ formData: finalFormData });
    });
    onDataChanged(this, { form_data: finalFormData });
  };

  objectHasKey = (object: unknown): object is JSONSchema6 => {
    return Object.prototype.hasOwnProperty.call(object, "enum");
  };

  customFieldTemplate = (props: FieldTemplateProps): JSX.Element => {
    const {
      children,
      classNames,
      description,
      errors,
      help,
      id,
      label,
      schema,
      uiSchema,
    } = props;
    const { typeOfTranslation } = this.props;
    const typeOfTranslationsToLookFor = ["phrase", "sentence"];
    if (schema && (schema as any).__additional_property) {
      return null;
    }
    const isBaseForm = label === "Base form";
    // Apparently this workaround is needed to get classNames from uiSchema
    const myClassNames = classNames + " " + (uiSchema["ui:classNames"] ?? "");
    const shouldBeFullWith =
      isBaseForm &&
      typeOfTranslationsToLookFor.find((name) => name === typeOfTranslation);

    return (
      <div
        ref={isBaseForm ? this.formDivRef : undefined}
        className={myClassNames}
        style={shouldBeFullWith ? { width: "100%" } : {}}
      >
        <label htmlFor={id}>{label}</label>
        {description}
        {children}
        {errors}
        {help}
      </div>
    );
  };

  guessForms = async (): Promise<void> => {
    const { languageCode, onDataChanged, token } = this.props;
    const { formData } = this.state;
    const gf_category = formData.gf_category;
    const forms = formData?.forms ?? {};

    // Currently "Get machine translation" button is not written in React,
    // therefore there's a possible scenario in which we need to get input value directly from
    // DOM instead of React state.
    if (!forms.lemma) {
      const textArea = this.formDivRef.current.querySelector("textarea");
      if (!textArea) {
        forms.lemma = "";
      } else {
        forms.lemma = textArea.value;
      }
    }

    try {
      const guessedFormData = await guessLexiconForms({
        gf_category,
        forms,
        languageCode,
        token,
      });
      const newFormData = { ...formData, forms: guessedFormData };
      this.setState({
        formData: newFormData,
      });
      store.dispatch(
        setDjangoToastOpen({
          content:
            "Forms automatically guessed. Please verify and change as necessary.",
          appearance: NotificationAppearance.SUCCESS,
        })
      );
      onDataChanged(this, { form_data: newFormData });
    } catch (err) {
      return;
    }
  };

  clearForms = (): void => {
    const { formData } = this.state;
    formData.forms = { lemma: formData.forms["lemma"] };
    this.setState({
      formData,
    });
  };

  render(): React.ReactElement {
    const formContext = {};
    const { jsonSchema, uiSchema, direction } = this.props;
    const { formData } = this.state;
    const { vocabularyId } = this.props;
    const enableGuessFormsButton = !!vocabularyId;
    return (
      <div style={{ direction: direction }} data-testid="lexicon-editor-form">
        <Form
          FieldTemplate={this.customFieldTemplate}
          fields={{ dropdownsearch: (DropdownSearchField as unknown) as Field }}
          formContext={formContext}
          formData={formData}
          onChange={this.onChange}
          schema={jsonSchema}
          uiSchema={uiSchema}
          className="lexicon-editor ui form"
        />
        {enableGuessFormsButton && (
          <Grid style={{ alignItems: "baseline" }}>
            <Button
              onClick={this.guessForms}
              size="small"
              style={{ marginTop: 10, marginBottom: 10 }}
              type={"button"}
            >
              Guess forms
            </Button>
            <Label basic style={{ border: "none", background: "none" }}>
              If present, forms other than the base form may also be used as
              input when guessing. To avoid this, you can{" "}
              <TextButton onClick={this.clearForms}>
                clear all other forms
              </TextButton>{" "}
              first.
            </Label>
          </Grid>
        )}
      </div>
    );
  }
}

export const LexiconEditorComp = connector(LexiconEditor);
