import $ from "jquery";
import { debounce } from "../utils/debounce";
import { NotificationAppearance } from "../api/djangoToastSlice";
import { showMessage } from "../utils/partialUtils";
import { fetchCustomerMetricsHtml } from "../api/metricsApi";
import { TaskId } from "../api/types";
import { CeleryTaskStatus } from "../utils/types";

// Create table for metrics
// TODO: Remove metrics-form once all metrics forms are updated to use the new form class
$(function () {
  $(".metrics-input").on("change", function () {
    $(".txu-metrics, .metrics-form").trigger("submit");
  });
});

const getMetricsTable = (
  url: string,
  token: string,
  taskId: TaskId,
  target: string,
  template: string,
  path: string
): void => {
  debounce(async () => {
    const { data } = await fetchCustomerMetricsHtml(
      url,
      token,
      taskId,
      template,
      path
    );
    if (data.status === CeleryTaskStatus.SUCCESS) {
      $(target).html(data.content);
      $("#csv-button").removeAttr("disabled");

      // for Machine Translations view
      onExpandRowClicked($(target));
      // wrapIfTableIsBig();
    } else if (data.status === CeleryTaskStatus.FAILURE) {
      showMessage(
        NotificationAppearance.ERROR,
        "An unexpected error occurred."
      );
    } else {
      getMetricsTable(url, token, taskId, target, template, path);
    }
  }, 3000)();
};

const onExpandRowClicked = (element: JQuery<HTMLElement>): void => {
  // expand rows with detailed infos
  const toggleSiblings = ({
    element,
    selector,
  }: {
    element: HTMLElement;
    selector: string;
  }): void => {
    $(element)
      .siblings(selector)
      .children()
      .each(function () {
        $(this).toggleClass("hide");
      });
  };
  $(element)
    .find(".action")
    .on("click", function () {
      toggleSiblings({ element: this.parentElement, selector: ".source_text" });
      toggleSiblings({
        element: this.parentElement,
        selector: ".translated_text",
      });
      toggleSiblings({
        element: this.parentElement,
        selector: ".replacement_text_details",
      });
      $(this).find(".icon").toggleClass("rotated");
    });
};

$(function () {
  const metricsForm = $(".txu-metrics, .metrics-form");
  if (metricsForm.length === 0) return;
  const url = metricsForm.data("url");
  const token = metricsForm.data("token");
  metricsForm.find('input[name="task_id"]').each(function () {
    getMetricsTable(
      url,
      token,
      $(this).val() as string,
      $(this).data("target"),
      $(this).data("template"),
      $(this).data("path")
    );
  });
});

// TODO: Sizing of large metrics tables still problematic; we might need this function again.
// function wrapIfTableIsBig(): void {
//   const table = $(".manage-table");
//   if (table[0].clientWidth > 1600) {
//     $(table).wrap("<div class='metrics-big-table'></div>");
//   }
// }
